import { graphql } from 'gatsby';
import React from 'react';
import Layout from '~/components/Layout/Layout';
import Metadata from '~/components/Metadata';
import { ProductListPage } from '~/components/ProductListPage/ProductListPage';
import { Collection } from '../types/types';

const KitTemplate: React.FC<ShopPageProps> = ({ data, pageContext }) => {
  const { page, catalog } = data;
  const { selectedIntent, selectedType } = pageContext;

  return (
    <Layout>
      <Metadata title={page.name} />
      <ProductListPage
        sections={page.sections}
        collections={catalog.collections}
        preSelectedIntent={selectedIntent}
        preSelectedType={selectedType}
      />
    </Layout>
  )
};

export const query = graphql`
  query ShopPage($locale: String) {
    catalog: contentfulCatalog(
      slug: { eq: "catalog" }
      node_locale: { eq: $locale }
    ) {
      collections {
        ...CollectionDetailsFragment
      }
    }
    page: contentfulPage(slug: { eq: "shop" }, node_locale: { eq: $locale }) {
      name
      sections {
        title
        cta {
          raw
        }
        description {
          id
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`;

export interface ShopPageProps {
  data: ShopPageData;
  pageContext: {
    selectedIntent?: string;
    selectedType?: string;
  }
}

export interface ShopPageData {
  catalog: {
    collections: Collection[];
  };
  page: {
    name: string;
    sections: {
      title: string;
      cta: {
        raw: string;
      };
      description: {
        childMarkdownRemark: {
          html: string;
        };
      };
    }[];
  };
}

export default KitTemplate;
